<template>
  <div>
    <div class="mr-4 mt-2">
      <b-row>
        <b-col cols="7" md="9">
          <div class="mr-lg-5 text-left">
            <b-button
              variant="success"
              size="sm"
              class="text-white ml-3"
              @click="goToNewPayment()"
              title="Adicionar pagamento"
            >
              <i class="fa fa-plus"></i>
            </b-button>
            <b-button v-b-modal.upload_modal
              class="mx-1 text-white"
              size="sm"
              variant="success"
              title="Importar pagamentos"
            >
              <i class="fa fa-upload" />
            </b-button>
            <b-button @click="emitGenerateXlsx()" variant="success" size="sm" class="mr-1" title="Exportar para Excel">
              <i class="fa fa-file-excel-o"></i>
            </b-button>
            <b-button v-b-toggle.accordion_filter
              class="mr-lg-5 mr-4 text-white"
              size="sm"
              variant="primary"
              title="Filtrar pagamentos"
            >
                <i class="fa fa-filter"></i>
            </b-button>
          </div>
        </b-col>
        <b-col cols="5" md="3" class="p-0 text-right">
          <b-button-group>
            <b-button
              variant="success"
              size="sm"
              class="text-white ml-lg-5"
              @click="openModalPayments('confirm')"
              v-b-modal.modal-payments
              title="Aprovar pagamento(s)"
            >
              <i class="fa fa-check-square"></i>
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              class="text-white mr-lg-3"
              @click="openModalPayments('decline')"
              v-b-modal.modal-payments
              title="Recusar pagamento(s)"
            >
              <i class="fa fa-window-close"></i>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-start align-items-center">
            <b-row class="d-flex align-items-center p-0 pl-md-4">
              Mostrar
              <b-col cols="4">
                <b-form-select
                  v-model="perPage"
                  :options="perPageOptions"
                  @input="emitPerPage"
                  size="sm"
                />
              </b-col>
              itens por página
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="p-0 pr-md-3">
          <div class="text-right">
            <b-button
              variant="warning"
              size="sm"
              @click="getPaymentsToApprove"
              class="text-white"
            >
              <i class="fa fa-clock-o"></i>
              Aguardando aprovação: {{ paymentsToApprove }}
            </b-button>
            <b-button
              variant="info"
              size="sm"
              @click="getRequestedAntecipations"
              class="text-white ml-2"
            >
              <i class="fa fa-exclamation-triangle"></i>
              Antecipações solicitadas: {{ requestedAntecipations }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-collapse id="accordion_filter" accordion="inputFilter">
      <b-card-body class="mx-2">
        <div class="row">
          <div class="col-md-12">
            <section>
              <form class="forms-sample mt-3">
                <div class="row">
                  <b-col lg="6">
                    <b-form-group label="Data Agendada:">
                      <b-form-input
                        v-model="filterPayments.scheduledDate"
                        type="date"
                        id="inputScheduledDate"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="CPF do Recebedor:">
                      <b-form-input
                        v-model="filterPayments.receiverFederalId"
                        type="tel"
                        v-mask="['###.###.###-##']"
                        id="inputFederalId"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Situação:"
                    >
                      <multiselect
                        v-model="filterPayments.approved"
                        :options="approvedFilterOptions"
                        tag-placeholder="Selecione"
                        placeholder="Selecione"
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel="X"
                        label="text"
                        track-by="value"
                        :multiple="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">  
                    <b-form-group label="Quantia:">
                      <money
                        v-model="filterPayments.amount"
                        type="tel"
                        id="inputAmount"
                        placeholder="Quantia"
                        size="sm"
                        v-bind="money"
                        maxlength="15"
                        class="form-control form-control-sm"
                      ></money>
                    </b-form-group>
                    <b-form-group label="Motivo:">
                      <b-form-input
                        v-model="filterPayments.description"
                        type="text"
                        id="inputDescription"
                        size="sm">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </div>
              </form>
            </section>
            <b-row>
              <b-col cols="6">
                <b-button-toolbar>
                  <b-button-group size="sm" class="mt-lg-3 ml-lg-2">
                    <b-button
                      @click="searchByDateFilter(7)"
                      class="
                        border-right
                        text-decoration-none text-dark
                      "
                      variant="link"
                      >7 dias</b-button
                    >
                    <b-button
                      @click="searchByDateFilter(15)"
                      class="
                        border-right
                        text-decoration-none text-dark
                      "
                      variant="link"
                      >15 dias</b-button
                    >
                    <b-button
                      @click="searchByDateFilter(30)"
                      class="text-decoration-none text-dark"
                      variant="link"
                      >30 dias</b-button
                    >
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
              <b-col cols="6">
                <div class="text-right mt-lg-3">
                  <b-button
                    class="ml-5"
                    @click="filterPaymentsApi()"
                    size="md"
                    variant="primary"
                    >Filtrar</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
    </b-collapse>
    <b-modal
      id="upload_modal"
      title="Importar Pagamentos"
      hide-header-close
      centered
    >
      <b-form-group>
        <b-form-file
          v-model="xlsx"
          :state="!$v.xlsx.$error"
          placeholder="Escolha ou arraste a planilha aqui"
          browse-text="Buscar"
        />
        <div
          v-if="!$v.xlsx.required"
          class="invalid-feedback"
        >
          <span>Selecione uma planilha!</span>
        </div>
      </b-form-group>
      <div class="my-2">
        <h6 class="text-muted">
          Clique <b-button variant="link" @click="callDownloadXlsx" class="p-0" size="sm">aqui</b-button> para baixar a planilha de exemplo.
        </h6>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="light"
          @click="cancel"
        >Cancelar</b-button>
        <b-button
          size="sm"
          variant="success"
          @click="callUploadXlsx"
        >Importar</b-button>
      </template> 
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect'
import PlgPaymentsApi from "../../apis/PlgPaymentsApi.vue";
import Toasts from "../Toasts.vue";
import moment from "moment";


export default {
  name: "PlgPaymentsAdmin",
  
  mixins: [
    PlgPaymentsApi,
    Toasts
  ],

  components: {
    Multiselect,
  },

  props: {
    paymentsToApprove: {
      type: Number,
      default: 0
    },
    requestedAntecipations: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      filterPayments: {
        scheduledDate: null,
        receiverFederalId: null,
        amount: 0,
        approved: null,
        description: null,
      },
      xlsx: null,
      perPageOptions: [5, 10, 20, 50, 100],
      perPage: 10,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      modalPayments: {
        title: null,
        action: null,
        button: null,
        askForSchedule: false,
      },
      approvedFilterOptions: [
        {text: "Aguardando aprovação", value: "waiting"},
        {text: "Aprovado", value: "approved"},
        {text: "Recusado", value: "declined"},
      ]
    }
  },

  validations() {
    return {
      xlsx: {
        required
      }
    }
  },

  methods: {
    goToNewPayment() {
      this.$router.replace({name: 'payments_new'})
    },
    async callUploadXlsx() {
      this.$v.$touch()
      if(!this.$v.$invalid) {
        this.uploadXlsx(this.xlsx);
        this.$bvModal.hide('upload_modal');
        await this.filterPaymentsApi();
      }
    },
    emitGenerateXlsx() {
      this.$emit('generateXlsx', true)
    },
    emitPerPage() {
      this.$emit('perPageChange', this.perPage)
    },
    async searchByDateFilter(days) {
      this.loader = true;
      let searchDate = moment().subtract(days, "days");
      searchDate = searchDate.toISOString().split("T")[0];
      const filter = {
        after_date: searchDate
      };
      this.$emit('searchByDate', filter)
    },
    async filterPaymentsApi() {
      this.filterPayments.limit = this.perPage;
      this.filterPayments.sort = this.sortBy;
      const getValues = (obj) => {
        return obj.value
      }
      const filter = {
        scheduled_date: this.filterPayments.scheduledDate || null,
        receiver_federal_id: this.filterPayments.receiverFederalId || null,
        amount: this.filterPayments.amount * 100 || null,
        approved: this.filterPayments.approved ? this.filterPayments.approved.map(getValues) : null,
        description: this.filterPayments.description || null,
      };
      this.$emit('filterPayments', filter)
    },
    async getPaymentsToApprove() {
      const payload = {
        approved: ["waiting", "antecipation_requested"]
      };
      this.$emit('listToApprove', payload)
    },
    async getRequestedAntecipations() {
      const payload = {
        antecipation_status: ["requested"]
      };
      this.$emit('listRequestedAntecipations', payload)
    },
    callDownloadXlsx() {
      this.downloadXlsx();
    },
    openModalPayments(action) {
      if (action === "confirm") {
        this.modalPayments.title = "Aprovar Pagamentos";
        this.modalPayments.action = "aprovados";
        this.modalPayments.button = "success";
        this.modalPayments.var = action;
      } else if (action === "decline") {
        this.modalPayments.title = "Recusar Pagamentos";
        this.modalPayments.action = "recusados";
        this.modalPayments.button = "danger";
        this.modalPayments.var = action;
      }
      this.$emit("openModal", this.modalPayments)
    },
  },
}
</script>