<template>
  <div>
    <Toasts ref="Toasts"></Toasts>
    <PlgPaymentsApi ref="PlgPaymentsApi"></PlgPaymentsApi>
    <section class="tables">
      <div class="card border rounded">
        <div class="mb-5">
          <slot name="header"></slot>
          <slot name="options"></slot>
        </div>
        <div class="mx-3">
          <b-table
            :items="items"
            id="table-list"
            :busy="loading"
            responsive
            :fields="fields"
            :current-page="currentPage"
            @sort-changed="sortChanged"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @filtered="onFiltered"
          >
            <template #table-busy>
              <div>
                <b-skeleton-table class="mt-2" :columns="5" :rows="3" />
              </div>
            </template>
            <template #head(select)>
              <b class="table-font">
                <b-form-checkbox
                  v-model="selectAll"
                  @change="selectAllRows"
                ></b-form-checkbox>
              </b>
            </template>
            <template #head(approved)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(approved)="data">
              <b-row class="my-1">
                <b-col>
                  <b-badge
                    pill
                    :variant="setStatusVariants(data.item.approved)"
                  >
                    <b class="table-font">
                      {{ translateStatus(data.item.approved) }}
                    </b>
                  </b-badge>
                </b-col>
              </b-row>
            </template>
            <template #head(antecipation_status)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(antecipation_status)="data">
              <b-row class="my-1">
                <b-col>
                  <b-badge
                    v-if="data.item.antecipation_status"
                    pill
                    :variant="setStatusVariants(data.item.antecipation_status)"
                  >
                    <b class="table-font">
                      {{ translateStatus(data.item.antecipation_status) }}
                    </b>
                  </b-badge>
                  <div v-else>N/A</div>
                </b-col>
              </b-row>
            </template>
            <template class="table-font" v-slot:cell(select)="data">
              <b-form-group>
                <b-form-checkbox
                  v-model="selectedPayments"
                  :disabled="checkIfClosed(data.item.approved)"
                  :value="data.item"
                ></b-form-checkbox>
              </b-form-group>
            </template>
            <template #head(receiver_name)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(receiver_name)="data">
              <b class="table-font">{{ data.item.receiver_name }}</b>
            </template>
            <template #head(amount)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(amount)="data">
              <b class="table-font">
                R$
                {{
                  data.item.antecipated_amount
                    ? formatAmount(data.item.antecipated_amount)
                    : formatAmount(data.item.amount)
                }}</b
              >
            </template>
            <template #head(paid)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(paid)="data">
              <b class="table-font"> {{ data.item.paid ? "Sim" : "Não" }}</b>
            </template>
            <template #head(description)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(description)="data">
              <b class="table-font"> {{ data.item.description }}</b>
            </template>
            <template #head(scheduled_date)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(scheduled_date)="data">
              <b class="table-font">{{
                formatDate(data.item.scheduled_date)
              }}</b>
            </template>
            <template #head(actions)="data">
              <b class="table-font">{{ data.label }}</b>
            </template>
            <template v-slot:cell(actions)="data">
              <b class="table-font">
                <router-link
                  v-if="adminView"
                  class="btn btn-warning btn-sm text-white btn-fw"
                  :to="{ name: 'payments_edit', params: { id: data.item.id } }"
                  :title="
                    checkIfClosed(data.item.approved) ? 'Detalhes' : 'Editar'
                  "
                >
                  <i
                    :class="
                      checkIfClosed(data.item.approved)
                        ? 'fa fa-search'
                        : 'fa fa-pencil'
                    "
                  ></i>
                </router-link>
              </b>
            </template>
          </b-table>
        </div>
        <div class="row" v-if="rows">
          <div class="col">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              @input="refreshPayments(null)"
              align="center"
              :per-page="perPage"
            ></b-pagination>
          </div>
        </div>
        <div class="row" v-show="!rows">
          <div class="col">
            <div class="card border-0 my-5" style="text-align: center">
              <i
                class="mdi mdi mdi-account-cash text-muted"
                style="font-size: 50px"
                >!</i
              >
              <h3 class="text-muted">Não há pagamentos!</h3>
              <h4 class="text-muted">Crie um novo ou cheque os filtros.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal id="modal-payments" centered>
      <template #modal-header>
        <span>{{ modalPayments.title }}</span>
      </template>
      <p class="my-4">
        Ao fazer isso os pagamentos selecionados serão
        {{ modalPayments.action }}. Tem certeza de que quer continuar?
      </p>
      <template #modal-footer="{ cancel, hide }">
        <b-button size="md" variant="secondary" @click="cancel()">
          <span class="cancel-button">Cancelar</span>
        </b-button>
        <b-button
          size="md"
          :variant="modalPayments.button"
          @click="
            checkForAntecipationRequests() && modalPayments.action === 'confirm'
              ? handleScheduleModal()
              : executePaymentsActions(modalPayments.var),
              hide()
          "
        >
          Confirmar
        </b-button>
      </template>
    </b-modal>
    <b-modal id="nested-modal-payments" v-model="askForScheduleModal" centered>
      <template #modal-header>
        <span>Antecipações Solicitadas</span>
      </template>
      <p class="my-1">
        Um ou mais pagamentos selecionados tiveram suas antecipações
        solicitadas. Deseja aprová-las?
      </p>
      <template #modal-footer>
        <b-button
          size="md"
          variant="secondary"
          @click="executePaymentsActions(modalPayments.var, true)"
        >
          <span class="cancel-button">Não</span>
        </b-button>
        <b-button
          size="md"
          :variant="modalPayments.button"
          @click="executePaymentsActions(modalPayments.var, false)"
        >
          Sim
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PlgPaymentsApi from "../../apis/PlgPaymentsApi.vue";
import Toasts from "../Toasts.vue";
import moment from "moment";

export default {
  name: "plg-payments-table",
  props: {
    adminView: {
      type: Boolean,
      default: false,
    },
    modalPayments: {
      type: Object,
      default() {
        return {
          title: null,
          action: null,
          button: null,
        };
      },
    },
  },
  components: {
    Toasts,
    PlgPaymentsApi,
  },
  computed: {
    skip() {
      return this.perPage * (this.currentPage - 1);
    },
  },
  data() {
    return {
      sortBy: "id",
      perPage: 10,
      lastPage: 1,
      filter: {},
      currentPage: 1,
      fields: [
        {
          key: "select",
          label: "Selecionar",
          sortable: false,
          class: "table-font",
        },
        {
          key: "approved",
          label: "Status",
          sortable: true,
          class: "table-font",
        },
        {
          key: "antecipation_status",
          label: "Status de Antecipação",
          sortable: true,
          class: "table-font",
        },
        {
          key: "receiver_name",
          label: "Para",
          sortable: false,
          class: "table-font",
        },
        { key: "amount", label: "Valor", sortable: true, class: "table-font" },
        { key: "paid", label: "Pago?", sortable: true, class: "table-font" },
        {
          key: "description",
          label: "Motivo",
          sortable: true,
          class: "table-font",
        },
        {
          key: "scheduled_date",
          label: "Data",
          sortable: true,
          class: "table-font",
        },
        { key: "actions", label: "", sortable: false, class: "table-font" },
      ],
      loading: false,
      askForScheduleModal: false,
      items: [],
      selectAll: false,
      selectedPayments: [],
      rows: 0,
      sortDesc: false,
    };
  },
  methods: {
    checkIfClosed(status) {
      return ["approved", "declined"].includes(status);
    },
    setStatusVariants(status) {
      switch (status) {
        case "requested":
          return "info";
        case "not_requested":
        case "waiting":
          return "warning";
        case "approved":
          return "success";
        case "declined":
          return "danger";
      }
    },
    translateStatus(status) {
      switch (status) {
        case "not_requested":
          return "Não solicitada";
        case "requested":
          return "Solicitada";
        case "waiting":
          return "Aguardando aprovação";
        case "approved":
          return "Aprovada";
        case "declined":
          return "Recusada";
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    checkForAntecipationRequests() {
      return this.selectedPayments.some(
        (item) => item.antecipation_status === "requested"
      );
    },
    async generateXlsxApi() {
      const filterPayload = {
        filter: this.filter,
      };
      await this.$refs.PlgPaymentsApi.getPaymentsApi(filterPayload, true);
    },
    handleScheduleModal() {
      this.askForScheduleModal = !this.askForScheduleModal;
    },
    formatDate(date) {
      let newDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return newDate;
    },
    formatAmount(amount) {
      let newAmount = amount / 100;
      return newAmount.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    },
    sortChanged(e) {
      this.sortBy = e.sortBy;
      if (e.sortDesc) this.sortBy = e.sortBy;
      this.refreshPayments();
    },
    changePerPage(perPage) {
      this.perPage = perPage;
      this.refreshPayments();
    },
    selectAllRows() {
      this.selectedPayments = this.selectAll
        ? this.countPaymentsToApprove().map((item) => {
            return item;
          })
        : [];
    },
    async refreshPayments() {
      this.loading = true;
      this.selectAll = false;
      this.selectAllRows();
      this.filter.sort = this.sortBy;
      this.filter.limit = this.perPage;
      this.filter.skip = this.skip;
      const payload = {
        filter: this.filter,
      };
      let response = await this.$refs.PlgPaymentsApi.getPaymentsApi(payload);
      if (response.data.status != "success") {
        this.$refs.Toasts.showToast(
          response.data.status,
          response.data.message
        );
      }
      this.items = response.data.payments;
      this.rows = response.data.payments_total_count;
      this.loading = false;
    },
    async tryToExecutePayment(paymentId, payload) {
      const balanceResponse =
        await this.$refs.PlgPaymentsApi.checkForEnoughBalance(paymentId);
      if (balanceResponse.status === 200) {
        const paymentResponse = await this.$refs.PlgPaymentsApi.payPaymentApi(
          paymentId,
          payload
        );
        return paymentResponse;
      } else {
        this.$refs.Toasts.showToast(
          balanceResponse.data.status,
          balanceResponse.data.message
        );
      }
    },
    async confirmOrCancelPayment(payment, paymentAction, payOnSchedule) {
      let response = null;

      const payload = {
        workspace: {
          id: localStorage.getItem("workspace"),
        },
        new_payment_data: {
          approved: paymentAction === "confirm" ? "approved" : "declined",
          antecipation_status:
            payment.antecipation_status === "requested"
              ? payOnSchedule || paymentAction === "decline"
                ? "declined"
                : "approved"
              : "not_requested",
          pay_on_schedule: paymentAction === "confirm" ? payOnSchedule : null,
          antecipated_amount: payOnSchedule ? null : payment.antecipated_amount,
        },
      };
      switch (paymentAction) {
        case "confirm":
          response = payOnSchedule
            ? await this.$refs.PlgPaymentsApi.updatePaymentApi(
                payment.id,
                payload
              )
            : await this.tryToExecutePayment(payment.id, payload);
          break;
        case "decline":
          response = await this.$refs.PlgPaymentsApi.updatePaymentApi(
            payment.id,
            payload
          );
      }
      this.$refs.Toasts.showToast(response.data.status, response.data.message);
      this.refreshPayments();
    },
    executePaymentsActions(action, payOnSchedule) {
      this.selectedPayments.forEach((entry) => {
        this.confirmOrCancelPayment(entry, action, payOnSchedule);
      });
      this.$bvModal.hide("nested-modal-payments");
      this.refreshPayments();
    },
    countPaymentsToApprove() {
      return this.items.filter((item) => {
        return item.approved === "waiting";
      });
    },
    countRequestedAntecipations() {
      return this.items.filter((item) => {
        return item.antecipation_status === "requested";
      });
    },
  },
  async mounted() {
    this.filter = await this.$refs.PlgPaymentsApi.getFilterForPaymentsApi();
    await this.refreshPayments();
    await this.$emit(
      "countedPaymentsToApprove",
      this.countPaymentsToApprove().length
    );
    await this.$emit(
      "countedRequestedAntecipations",
      this.countRequestedAntecipations().length
    );
  },
};
</script>

<style>
.cancel-button {
  color: white;
}
.table-font {
  font-size: 0.9rem;
}
</style>
