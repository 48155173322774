<template>
  <div>
    <transition name="slide-up" appear mode="out-in">
      <div>
        <section class="text-center">
          <plg-payments-table
            @countedPaymentsToApprove="($event) => {
              paymentsToApproveAmount = $event;
            }"
            @countedRequestedAntecipations="($event) => {
              requestedAntecipationsAmount = $event;
            }"
            :adminView="true"
            :modalPayments="modalPayments"
            ref="PlgPaymentsTable"
          >
            <template v-slot:header>
              <div class="row p-3">
                <div class="col-md-12">
                  <h3 class="m-0">
                    <span class="pull-left mt-2 text-muted">Ordens de Pagamentos</span>
                  </h3>
                </div>
              </div>
            </template>
            <template v-slot:options>
              <PlgPaymentsAdmin
                :paymentsToApprove="paymentsToApproveAmount"
                :requestedAntecipations="requestedAntecipationsAmount"
                @listToApprove="refreshPayments($event, 1)"
                @listRequestedAntecipations="refreshPayments($event, 1)"
                @openModal="setModalPaymentsData"
                @perPageChange="perPageChange"
                @filterPayments="refreshPayments"
                @searchByDate="refreshPayments"
                @generateXlsx="generateXlsx"
              ></PlgPaymentsAdmin>
            </template>
          </plg-payments-table>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import PlgPaymentsTable from "../../components/payments/PlgPaymentsTable.vue";
import PlgPaymentsAdmin from "../../components/payments/PlgPaymentsAdmin.vue";


export default {
  name: 'via-payments',
  components: {
    PlgPaymentsTable,
    PlgPaymentsAdmin,
  },
  data: function() {
    return {
      modalPayments: {},
      paymentsToApproveAmount: 0,
      requestedAntecipationsAmount: 0
    };
  },
  methods: {
    onFilter(){
      this.$refs.PlgPaymentsTable.refreshPayments();
    },
    setModalPaymentsData($event) {
      this.modalPayments = $event;
    },
    async refreshPayments($event) {
      this.$refs.PlgPaymentsTable.filter = $event;
      this.$refs.PlgPaymentsTable.refreshPayments();
    },
    async perPageChange($event) {
      this.$refs.PlgPaymentsTable.perPage = $event;
      this.$refs.PlgPaymentsTable.refreshPayments();
    },
    generateXlsx(){
      this.$refs.PlgPaymentsTable.generateXlsxApi();
    },
  },
  mounted() {
    if(!this.$can('view', 'apis')) {
      this.$router.push({name: 'home'});
    }
  }
};
</script>
